import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import '../styles/create_dish.css';

const PhonesEdit = () => {
  const [phones, setPhones] = useState([]);
  const [newPhone, setNewPhone] = useState('');
  const [editingPhone, setEditingPhone] = useState(null);

  const fetchPhones = () => {
    axios.get(`https://back.eltaqwarestaurant.com/api/admin/info`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setPhones(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response?.data?.message || 'Error fetching phone numbers');
      });
  };

  useEffect(() => {
    fetchPhones();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://back.eltaqwarestaurant.com/api/admin/info', {
        phone: newPhone
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success("تم اضافة رقم الهاتف بنجاح");
      fetchPhones();
      setNewPhone('');
    } catch (error) {
      console.error('Error adding phone:', error);
      toast.error(error.response?.data?.message || 'Error adding phone number');
    }
  };

  const handleUpdatePhone = async (id, phone) => {
    try {
      const response = await axios.post(`https://back.eltaqwarestaurant.com/api/admin/info/${id}`, {
        phone: phone
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success("تم تحديث رقم الهاتف بنجاح");
      fetchPhones();
      setEditingPhone(null);
    } catch (error) {
      console.error('Error updating phone:', error);
      toast.error(error.response?.data?.message || 'Error updating phone number');
    }
  };

  const handleDeletePhone = async (id, e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://back.eltaqwarestaurant.com/api/admin/info/delete/${id}`);
      toast.success("تم حذف رقم الهاتف بنجاح");
      fetchPhones();
    } catch (error) {
      console.error('Error deleting phone:', error);
      toast.error(error.response?.data?.message || 'Error deleting phone number');
    }
  };

  const columns = [
    {
      name: 'رقم الهاتف',
      sortable: true,
      selector: row => {
        if (editingPhone === row.id) {
          return (
            <input
              type="text"
              defaultValue={row.phone}
              onBlur={(e) => handleUpdatePhone(row.id, e.target.value)}
              autoFocus
            />
          );
        }
        return <p>{row.phone}</p>;
      }
    },
    {
      name: 'قرارات',
      sortable: false,
      cell: row => (
        <div className="action-buttons">
          <button 
            onClick={() => setEditingPhone(row.id)} 
            className='edit-button'
          >
            <i className="fa-solid fa-edit"></i>
          </button>
          <button 
            onClick={(e) => handleDeletePhone(row.id, e)} 
            className='negative_karar'
          >
            <i className="fa-solid fa-trash"></i>
          </button>
        </div>
      )
    },
  ];

  return (
    <div className='create_delete'>
      <form className="create_form" onSubmit={handleSubmit}>
        <div className="create_form_container">
          <h1>اضافة رقم هاتف جديد</h1>
          <div className="input_holder">
            <label>رقم الهاتف</label>
            <input
              type="text"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              className="input_field"
              placeholder="ادخل رقم الهاتف"
            />
          </div>
          <input type="submit" className="input_submit" value="أضف رقم الهاتف" />
        </div>
      </form>

      <div className='table_div'>
        <DataTable
          columns={columns}
          data={phones}
          pagination
          striped
          defaultSortAsc={false}
        />
        <Tooltip id="my-tooltip" place="top" />
      </div>
      <ToastContainer />
    </div>
  );
};

export default PhonesEdit;
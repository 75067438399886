import React, { useState } from 'react';
import Orders from './admin/Orders';
import CreateDish from './admin/CreateDish';
import CreateCategory from './admin/CreateCategory';
import SliderEdit from './admin/SliderEdit';
import PhonesEdit from './admin/PhonesEdit';
import "./styles/admin.css";

const Admin = () => {
  const [selectedComponent, setSelectedComponent] = useState('Orders');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // To track if the user is authenticated
  const [passwordInput, setPasswordInput] = useState(''); // Password input field
  const correctPassword = 'taqwaadmin123'; // Set your static password here

  const handleComponentChange = (componentName) => {
    setSelectedComponent(componentName);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'Orders':
        return <Orders />;
      case 'CreateDish':
        return <CreateDish />;
      case 'CreateCategory':
        return <CreateCategory />;
      case 'SliderEdit':
        return <SliderEdit />;
      case 'PhonesEdit':
        return <PhonesEdit />;
      default:
        return null;
    }
  };

  const handleLogin = () => {
    if (passwordInput === correctPassword) {
      setIsAuthenticated(true); // Grant access if the password is correct
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  // If the user is not authenticated, show the password input form
  if (!isAuthenticated) {
    return (
      <div className="password-protection">
        <h2>أدخل كلمة السر</h2>
        <input
          type="password"
          value={passwordInput}
          onChange={(e) => setPasswordInput(e.target.value)}
        />
        <button onClick={handleLogin}>تسجيل</button>
      </div>
    );
  }

  // Once authenticated, render the admin panel
  return (
    <div className="admin">
      <button className='admin_nav_toggle' onClick={toggleNav}><i className="fa-solid fa-bars"></i></button>
      <div className={`admin_nav ${isNavOpen ? 'right' : ''}`}>
        <button onClick={toggleNav}><i className="fas fa-arrow-right"></i></button>
        <button onClick={() => handleComponentChange('Orders')}>طلبات</button>
        <button onClick={() => handleComponentChange('CreateDish')}>اضافة طبق جديد</button>
        <button onClick={() => handleComponentChange('CreateCategory')}>اضافة صنف</button>
        <button onClick={() => handleComponentChange('SliderEdit')}>تعديل الصفحة الرئيسية</button>
        <button onClick={() => handleComponentChange('PhonesEdit')}>تعديل أرقام الهاتف</button>
      </div>

      <div className='admin_components'>
        {renderSelectedComponent()}
      </div>
    </div>
  );
};

export default Admin;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/footer.css";
import logo from "../assets/logo.jpg";

const Footer = () => {
  const [phones, setPhones] = useState([]);

  useEffect(() => {
    const fetchPhones = async () => {
      try {
        const response = await axios.get("https://back.eltaqwarestaurant.com/api/admin/info", {
          headers: { Accept: "application/json" },
        });
        setPhones(response.data.data.reverse());

      } catch (error) {
        console.error("Error fetching phone numbers:", error);
      }
    };

    fetchPhones();
  }, []);

  return (
    <>
      {phones && phones.length > 0 && (
        <footer id="contact" className="footer">
          <footer className="footer_container">
            <div className="footer_info">
              <img src={logo} alt="مطعم التقوي" />
              <div className="footer_info_text">
                <h1>مطعم التقوي</h1>
                <p>
                  الوجهة الأولى في مصر لتذوق أشهى وأطيب أنواع المندي المعد بأعلى
                  درجات الاحترافية والخبرة التي تمتد لأكثر من 30 عامًا
                </p>
              </div>
            </div>

            <div className="footer_contact">
              <h1>تواصل معنا</h1>
              <div className="footer_contact_phone">
                {phones.map((phoneData, index) => (
                  <a
                    key={phoneData.id || index}
                    className="phone"
                    href={
                      phoneData.phone.startsWith("01")
                        ? `tel:2${phoneData.phone}`
                        : undefined
                    }
                  >
                    <i className="fa-solid fa-phone"></i> {phoneData.phone}
                  </a>
                ))}
              </div>

              <p className="footer_address">
                ٦٨ محمد المقلد، المنطقة الثامنة، مدينة نصر، محافظة القاهرة
              </p>

              <div className="footer_social">
                <a href="https://api.whatsapp.com/message/EAE6TDN473F3E1">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a href="https://www.facebook.com/eltaqwarestaurant">
                  <i className="fa-brands fa-square-facebook"></i>
                </a>
                <a href="https://www.instagram.com/eltaqwa_restaurant?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                  <i className="fa-brands fa-square-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@eltaqwarestaurant">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
              </div>
            </div>
          </footer>

          <footer className="footer_footer">
            الحقوق محفوظة © 2024 | مطعم التقوي ™
          </footer>
        </footer>
      )}
    </>
  );
};

export default Footer;
